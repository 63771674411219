import React from 'react'
import './imagescontainer.css'
import Swiper from 'swiper'
 import PropTypes from 'prop-types'


export default class ImagesContainer extends React.Component {
    static propTypes={
        imageUrls:PropTypes.array.isRequired
    }
    componentDidMount() {
        this.swiper = new Swiper('.swiper-container', {
            pagination: {  //分页器
                        el: '.swiper-pagination'
                    },
            effect: 'coverflow',
            grabCursor: true,
            centeredSlides: true,
            slidesPerView: 'auto',
            coverflow: {
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true
            }
        })
        if (this.props.imageUrls && this.props.imageUrls.length > 4) {
            this.swiper.slideTo(2)
        }
    }

    componentWillReveiveProps(nextProps){
        // this.swiper.update(true)
    }
    render(){
        // const a2=this.props.imageUrls.map((image,index)=><div key={index} className="swiper-slide" style={{backgroundImage:`url(${require("../a2/3.png")})`}} ></div>)

        const images=this.props.imageUrls.map((image,index)=><div key={index} className="swiper-slide" style={{backgroundImage:'url(' +image + ')'}} ></div>)
        return (
            <div className="images-container">
                <div className="swiper-container">
                    <div className="swiper-wrapper">
                        {images}
                    </div>
                    <div className="swiper-pagination">
                    </div>
                </div>
            </div>
        )
    }


}