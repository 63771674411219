import React from "react"
import MyNavLink from './my-nav-link'
import '../css/header.css'
import Footer from './footer_a1'

class LayoutA1 extends React.Component {

  render() {
    const { location, title, children } = this.props

    const navs =[{text:"首页",path:'/demos/themeA1/home',style:'link'},
      {text:"报名",style:'link',path:'/demos/themeA1/enroll'
      },
      {text:"赛事信息",
        style:'subMenu',
        path:'/demos/themeA1/raceInfo/rules',
        children:[
          {
            text:'赛事规程',
            path:'/demos/themeA1/raceInfo/rules'
          },
          {
            text:'地图信息',
            path:'/demos/themeA1/raceInfo/map'
          }
        ]
      },
      {text:"查看成绩",
        style:'link',path:'/demos/themeA1/achievement',
      },
      {text:"照片",
        style:'link',
        path:"/demos/themeA1/photos",
      },
      {text:"个人中心",
        style:'link',
        path:'/demos/themeA1/profile/login',
      },
    ]
    const color="#fc9816"
     const orgs=[{type: '主办单位', desc: '中国田径协会 江苏省体育局 簇格技术产业开发区管理委员会'}, {type: '承办单位', desc: '苏州体育局 苏州簇格工委宣传部 苏州簇格文化体育和旅游局'}, {type: '冠名单位', desc: '苏州簇格技术产业股份有限公司'}, {type: '运营支持单位', desc: '苏州市簇格软件有限公司'}, {type: '运营单位', desc: '江苏省体育竞赛有限公司'} ]
    const raceorg={name: '苏州簇格马拉松组委会', address: '苏州工业园区星桂街', phone: '0512-00000000', wx: '————'}
    const recordNo='苏ICP备——号 版权所有2018苏州簇格马拉松 ALL RIGHTS RESEREVD'
    return (
      <div>
        <div className="web-top">
          <div className="top-bg"></div>
          <div></div>
        </div>
        <Header navs={navs} color={color}/>
        <main >{children}</main>
        <Footer raceorg={raceorg} orgs={orgs} recordNo={recordNo} color={color}/>
      </div>
    )
  }
}


class Header extends React.Component {
  state={
    showmenu:false,
  }
  showMenu=()=>{
    this.setState({
      showmenu:!this.state.showmenu
    })
  }
  showSubmenu=()=>{
    this.setState({
      showSubmenu:!this.state.showSubmenu
    })
  }
  render() {
    const {navs,color}=this.props
    const navlist=navs.map((nav,index)=><div className="menu" key={index}>
      <MyNavLink className='first-menu' to={nav.path}><div style={{position:'relative'}}>{nav.text}</div><div className="menu-item-shadow"></div></MyNavLink>
      <div className="SubMenu" > {nav.children&&nav.children.map((child,index)=><div key={index}><MyNavLink  to={child.path}><div  className="submenu-item">{child.text}</div></MyNavLink></div>)}
      </div>
    </div>)
    const navlistPhone=navs.map((nav,index)=><div className="menu-phone" key={index}>
      <MyNavLink  activeStyle={{color:color}}  to={nav.path}><div className="menu-phone-content">{nav.text}</div></MyNavLink>
      <div className="SubMenu-phone" > {nav.children&&nav.children.map((child,index)=><div key={index}><MyNavLink activeStyle={{color:color}} to={child.path} ><div  className="submenu-item-phone">{child.text}</div></MyNavLink></div>)}</div>
    </div>)

    return(
      <div className="menu-container">
        <div className='logo'>
          <img className="logo-img" alt="cugelogo" src={require("../images/logo1.png")}></img>
        </div>
        <div className="menu-icon">
          <img src={require('../images/menu.png')} alt="menuicon" onClick={this.showMenu}/>
        </div>
        <div className="menus" >
          {/*导航路由链接*/}
          {navlist}
        </div>
        <div className="menus-phone" style={{display:this.state.showmenu?'block':'none'}}>
          {/*导航路由链接*/}
          {navlistPhone}
        </div>
      </div>
    )
  }
}

export default LayoutA1
