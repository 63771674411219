import React from "react"
import LayoutA1 from "../../components/layout-a1"
import './home.css'
import ImagesContainer from '../../components/imagescontainer'
import CountDown from '../../components/countdown'
import MyNavLink from '../../components/my-nav-link'
import news from "../../../content/news"
import announcement from "../../../content/announcement"

class Home extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      redirectLeft:false,
      redirectRight:false
    }
  }

  componentDidMount() {
    let width=document.body.clientWidth
    console.log(width)
    this.setState({width})
    let count = 1000
    const timer = setInterval(() => {
      count--
      this.setState({
        count,
      }, () => {
        if (count === 0) {
          clearInterval(timer);
          this.setState({
            count: 0,
          })
        }
      })
    }, 1000)
  }

  routePushLeft=(e)=>{
    console.log(e)
    this.props.history.push('/'+e)
  }

  routePushRight=(e)=>{
    console.log(e)
    this.props.history.push('/'+e)
  }

  toNews=()=>{
    console.log('1')
    this.props.history.push('/news')
  }
  render() {
    const { data } = this.props
    // const siteTitle = data.site.siteMetadata.title
    // const posts = data.allMarkdownRemark.edges
    const color='#fc9816'
    const leftRoute='enroll'
    const rightRoute='login.js'
    // if(this.state.redirectRight){
    //   // return <Redirect push to="/raceInfo" />
    // }
    const photos=['//cdni.cugefit.com/szmls20170312/s/ae5a4729-a6c6-478b-b2ae-409bedfcc70c_900x600.jpeg',
      '      //cdni.cugefit.com/szmls20170312/s/5aa17d5b-dcf4-4661-9926-e88239a4be5c_900x600.jpeg',
      '//cdni.cugefit.com/szmls20170312/s/ed8ef306-5795-4ec4-ad65-7c405ea8c18b_900x600.jpeg',
      '//cdni.cugefit.com/szmls20170312/p/0f901f59-365a-48cf-9bdb-745aae9784cc_900x1190.jpeg',
      '//cdni.cugefit.com/szmls20170312/p/1a854aa1-e2f5-42f6-b27c-e94a4890d086_900x637.jpeg',
      '//cdni.cugefit.com/szmls20170312/p/21d92fcc-5ba6-450a-8c7f-43042769f7cf_900x474.jpeg',
      '//cdni.cugefit.com/szmls20170312/p/591f320c-cd80-4882-b8c8-22e1b9eca053_900x600.jpeg',
      '//cdni.cugefit.com/szmls20170312/p/5d76a590-9d11-4e59-aebb-9eaaf89cf752_900x642.jpeg',
      '//cdni.cugefit.com/szmls20170312/p/75b7f65c-e685-493a-a79d-e4796028c6dc_900x651.jpeg']
    const icons=[
      "//cdn.chinarun.com/upload/site/1000/img/art/20170519/20170519120428274.jpg?_t_=1495195468",
      "//cdn.chinarun.com/upload/site/1132/img/art/201907/12/ce913996-b838-4572-ab03-b371294f1e87.jpg?_t_=1562949035",
      "//cdn.chinarun.com/upload/site/1000/img/art/20160721/20160721095233009.jpg?_t_=1469094753",
      "//cdn.chinarun.com/upload/site/1132/img/art/201910/10/ad63df0e-978e-4f0f-ae25-3e58c99db190.jpg?_t_=1570701899"]
    const newslist=news.news.map((news,index)=><div key={index}><a href={news.path}>{index+1}、{news.name}</a></div>)

    const announcementlist=announcement.announcements.map((announcement,index)=><div key={index}><a href={announcement.path}>{index+1}、{announcement.name}</a></div>)

    return (
      <LayoutA1 location={this.props.location} >
        <div className='poster'>
        </div>
        <div className="btn-container">
          <MyNavLink to="/demos/themeA1/enroll" className="enroll-btn" >
            <img className="enroll-bg-img-left" alt="hello" src={require("./images/enroll_bg1.png")}></img>
            <img className="enroll-bg-img-right" alt="hello" src={require("./images/enroll_bg2.png")}></img>
            <div className="enroll">报名</div>
          </MyNavLink>
          <a href="#" className="search-num" style={{background:color}} >
            <img className="number-bg-img-right" alt="hello" src={require("./images/number_bg.png")}></img>
            <div className="num">参赛号</div>
          </a>
        </div>
        <div className="count-down-container">
          <CountDown count={this.state.count}/>
          {/*<div>{this.state.time}</div>*/}
        </div>


        <div className="news-container" >
          <div className="news-list">
            {newslist}
          </div>
          <div style={{flex:1,display:'flex',justifyContent:'flex-end'}}>
            <div className="news-icon">
              <img alt="hello" src={require('./images/news.png')}/>
              <div style={{background:color+'20'}}></div>
            </div>
          </div>

        </div>

        <div className="news-container-phone" style={{display:this.state.width<480?'block':'none'}}>
          <div className="news-title-phone" style={{color:color}}>
            <img alt="hello" src={require('./images/news.png')}/>
            <div>新闻</div>
          </div>
          <div className="news-list">
            {newslist}
          </div>
        </div>


        <div className="announcement-container">
          <div style={{flex:1}}>
            <div className="announcement-icon">
              <img alt="hello" src={require('./images/announcement.png')}/>
              <div style={{background:color+'20'}}></div>
            </div>
          </div>

          <div className="announcements-list">
            {announcementlist}
          </div>
        </div>

        <div className="announcement-container-phone" style={{display:this.state.width<480?'block':'none'}}>
          <div className="announcement-title-phone" style={{color:color}}>
            <img alt="hello" src={require('./images/announcement.png')}/>
            <div>公告</div>
          </div>
          <div className="announcements-list">
            {announcementlist}
          </div>
        </div>


        <div className="special-photos">
          <div className="show-photos-title">精彩照片</div>
          <div className="title-shadow" style={{background:color+'95'}}></div>
          <ImagesContainer  imageUrls={photos}/>
        </div>

        <div className="show-supporters">
          <div className="show-icon-title">赛事赞助商</div>
          <div className="icon-title-shadow" style={{background:color+'95'}}></div>
          <div>
            {
              icons.map((icon, index) =>(
                <div key={index} className="supporter-icon-container">
                  <div className="supporter-icon"><img alt="hello" src={icon}/></div>
                </div>
              ))
            }
            <div style={{clear:'both'}}></div>
          </div>
        </div>

      </LayoutA1>
    )
  }
}

export default Home