import React from 'react'
import './countdown.css'
import PropTypes from "prop-types"
export default class CountDown extends React.Component{
  static propTypes={
   count:PropTypes.number.isRequired
  }
    render() {
      let days=Math.floor(this.props.count / 60 % 60)
      let h=Math.floor(this.props.count / 60 % 60)
      let minutes = Math.floor(this.props.count / 60 % 60)
      let seconds = Math.floor(this.props.count % 60)
        return(
            <div className="count-down">
                <div className="day">
                    <div className="day-text">{days}</div></div>
                <div className="hour">
                    <div className="hour-text">{h}</div>
                </div>
                <div className="minute">
                    <div className="minute-text">{minutes}</div>
                </div>
                <div className="second">
                    <div className="second-text">{seconds}</div>
                </div>
                <div className="count-down-bottom">
                    <img src={require("../templates/themeA1/images/countdown.png")} alt="hello" className=""></img>
                    <div className="time-name">
                        <div>天</div>
                        <div>时</div>
                        <div>分</div>
                        <div>秒</div>
                    </div>
                </div>


            </div>
        )
    }
}